<template>
    <div class="event_calendar">
         <!-- 加载动画 -->
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
        <Header></Header>
        <div class="container clearfix">
            <!-- 地区选择 -->
            <!-- <ul class="calendar_address f_left">
                <li :class="['address_li', {'active': areaIndex == 0}]" @click="chooseArea('', 0)">不限</li>
                <li
                  :class="['address_li', {'active': areaIndex == index + 1}]"
                  v-for="(area, index) in provinceArr"
                  :key="index"
                  @click="chooseArea(area, index + 1)"
                >{{area}}</li>
            </ul> -->
            <!-- 日历 -->
            <div class="calendar_box f_left">
                <div class="calendar_search_box clearfix">
                    <!-- 日历 -->
                    <div class="calendar_container f_left" ref="calendar">
                        <m-calendar
                          :value="calendar1.value"
                          :events="calendar1.events" 
                          :lunar="true"
                          @selectTime="selectTime"
                          @selectDay="selectDay"
                        ></m-calendar>
                    </div>
                    <!-- 搜索结果展示 -->
                    <div class="search_result_box f_left">
                        <h2 class="search_result_title">{{resultTitle}}</h2>
                        <div class="result_box" v-if="resultArr.length">
                            <ul class="search_result_list" v-for="(subItem, subIndex) in resultArr" :key="subIndex">
                                <li
                                  class="search_result_li"
                                  @click="toEventDetail(subItem.id)"
                                 v-if="(subItem.state & 0XF0)!==16">
                                    <span class="event_date" >{{subItem.title}}</span>
                                    <!-- <span :class="['event_name', {'more_event': subIndex != 0}]">{{subItem.name}}</span> -->
                                </li>
                                <li
                                  class="search_result_li"
                                  v-else
                                >
                                    <span class="event_date" style="color:grey
">{{subItem.title}}</span>
                                    <!-- <span :class="['event_name', {'more_event': subIndex != 0}]">{{subItem.name}}</span> -->
                                </li>
                            </ul>
                        </div>
                        <div class="no_result_tips" v-else>
                            <i class="no_result_icon"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer footerBgColor="#FBFBFB"></Footer>
    </div>
</template>
<script>
import { getMonthDate } from '@/config/utils'
import { calendarInterface } from '@/config/request'
import moment from 'moment'
export default {
    name: 'eventCalendar',
    data() {
        return {
            startDate: '', // 开始时间
            endDate: '', // 结束时间
            area: '', // 地区
            year: '', // 年份
            month: '', // 月份
            day: '', // 日期
            calendar1: { // 日历数据
                value: [],
                events: {}
            },
            provinceArr: [], // 省份数组
            areaIndex: 0, // 选择省份
            hasResult: false, // 是否有搜索结果
            allResultArr: [], // 全部的搜索结果
            resultArr: [], // 部分的搜索结果
            resultTitle: '', // 搜索结果的标题展示
            isLoading:true,
            getdayArr:[]
        }
    },
    methods: {
        // 获取数据
        getData() {
            this.isLoading = true
            this.area = this.area || ''
            calendarInterface.getData({
                // date: this.startDate, // 开始时间
                date:this.startDate
                // endDate: this.endDate, // 结束时间
                // area: this.area, // 地区
            }).then(res => {
                this.isLoading = false
                if(res.code == 200) {
                    this.getdayArr=res.data
                    let data = res.data
                    let result = []
                    this.calendar1.events = {}
                    for (let k in data) {
                        let key = `${this.year}/${this.month}/${Number(k)+1}`
                        // console.log(data[k].matches.length);
                        this.calendar1.events[key] = data[k].matches.length? data[k].matches.length + '场赛事' :''
                        data[k].matches.map(item => {
                            // item.day = item.day < 10 ? '0' + item.day : item.day
                            // console.log(item);
                            if(item){
                                result.push(item)
                            }
                            // item?result.push(item):''
                        })
                        // result.push(data[k].matches)
                    }
                    this.allResultArr = result
                    console.log(this.getdayArr);
                    this.resultArr = this.allResultArr
                    if(this.resultArr.length > 0) {
                        this.hasResult = true
                    } else {
                        this.hasResult = false
                    }
                    this.resultTitle = !this.area ? `${this.year}年${this.month}月赛事信息` : `${this.year}年${this.month}月${this.area}赛事信息`
                }
            })
        },
        // 获取省份
        getProvince() {
            calendarInterface.getProvince({}).then(res => {
                if(res.code == 1) {
                    let data = res.objectData
                    let hortProvince = []
                    let normalProvince = []
                    let provinceList = data.province_list
                    // 热门城市
                    data.hots.forEach(item => {
                        hortProvince.push(item.name)
                    })
                    // 其他城市
                    for(let k in provinceList) {
                        provinceList[k].forEach(item => {
                            normalProvince.push(item.name)
                        })
                    }
                    this.provinceArr = [...hortProvince, ...normalProvince]
                }
            })
        },
        // 获取今天的日期
        getTodayDate() {
            var date = new Date()
            var year = date.getFullYear()
            var month = date.getMonth() + 1
            var day = date.getDate()
            this.calendar1.value = [year, month, day]
            this.year = year// 年份
            this.month = month // 月份
            this.day = day // 日期
            this.startDate = getMonthDate(year, month).startDate // 开始时间
            this.endDate = getMonthDate(year, month).endDate // 结束时间
        },
        // 选择地区筛选
        chooseArea(area, index) {
            this.area = area
            this.areaIndex = index
            this.getData()
        },
        // 选择年或者月
        selectTime(year, month) {
            console.log(year, month);
            this.year = year
            this.month = month
            this.startDate = getMonthDate(year, month).startDate+' 00:00:00' // 开始时间
            // this.endDate = getMonthDate(year, month).endDate // 结束时间
            this.getData()
        },
        // 选择具体某一天
        selectDay(value) {
            console.log(value);
            let day = `${value[0]}-${value[1]<10?'0'+value[1]:value[1]}-${value[2]<10?'0'+value[2]:value[2]}`
            console.log(day);
            let singleResult = []
            let lastResultArr = []
            console.log(this.getdayArr);
            this.getdayArr.forEach(item=>{
if(day==item.date){
    this.resultArr=item.matches
}
            })

            console.log(this.resultArr);
             this.$forceUpdate()
            // this.allResultArr.forEach((item, index) => {
            //     item.forEach(subItem => {
            //         if(day == subItem.day) {
            //             singleResult.push(subItem)
            //         }
            //     })
            // })
            // lastResultArr.push(singleResult)
            // this.resultArr = lastResultArr
            // this.resultArr.forEach(item => {
            //     this.hasResult = item.length > 0 ? true : false
            // })
            
            this.resultTitle = !this.area ? `${this.year}年${this.month}月${value[2]}日赛事信息` : `${this.year}年${this.month}月${value[2]}日${this.area}赛事信息`
        },
        // 点击去赛事详情
        toEventDetail(eventId) {
            this.$router.push({
                path: '/eventDetail',
                query: {
                    eventId: eventId
                }
            })
        }
    },
    created() {
        this.getTodayDate()
        this.startDate=moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        this.getData()
        this.getProvince()
    },
}
</script>
<style scoped>
    @import './style/eventCalendar.css';
</style>